.selectTypeLectun {
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: 200px;
  padding: 18px;
  border-radius: 15px 15px 0 0;
  box-sizing: border-box;
  background-color: #FFFFFF;
  box-shadow: 0px -5.38px 4.05px 0px #4032AB0C;
  animation: slideToBottom 0.2s ease-out forwards;
}

.selectTypeLectun, .contentModal {
  > h3 {
    margin: 0;
    text-align: center;
    font-family: Ubuntu-Medium;
    font-weight: 500;
    font-size: 16px;
    color: #494F66;
  }

  > div {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    > button {
      height: 50px;
      border-radius: 6px;
      background-color: #F5F8FE;
      border: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      padding: 0 10px;
      font-family: Ubuntu;
      font-size: 16px;
      color: #5B6175;
      cursor: pointer;
    }
  }
}

.contentModal {
  width: 100%;
  
  > div {
    > button {
      height: 60px;
      img { width: 18px; }
    }
  }
}

.check {
  margin-left: auto;
}

.closing {
  animation: slideToBottomReverse 0.2s ease-out forwards;
}

@keyframes slideToBottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideToBottomReverse {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.selectTypeLectunModal {
  max-width: 468px;
  overflow: hidden;
  position: relative;
  width: 100%;
  animation: openModal 0.5s ease;
}

.closingModal {
  animation: closeModal 0.5s ease;
}

@keyframes openModal {
  0% { transform: translateY(-30px); }
  100% { transform: translateY(0); }
}

@keyframes closeModal {
  0% { transform: translateY(0); }
  100% { transform: translateY(-30px); }
}