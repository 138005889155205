.guard-view-desk {
    max-width: none !important;
    width: 100% !important;
    height: 100%;
    overflow: auto;
    padding: 12px 24px !important;
    background-color: #F5F7FF;

    .alarm-container{
        margin-top: 10px;

        &__count {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 29px;
            margin: 28px 0 22px;

            h4, p { margin: 0; }

            > h4 {
                font-family: Ubuntu-Medium;
                font-size: 14px;
                font-weight: 500;
                color: #494F66;
            }

            > div {
                display: flex;
                align-items: center;
                gap: 10px;
                font-family: Ubuntu;
                font-size: 12px;
                color: #494F66;
            }
        }

        &__list {
            min-height: 615px;
            border-radius: 10px;
        }

        &__thead {
            margin: 3px 0;
            height: 30px;
            display: flex;
            align-items: center;
            background: #EBF2FE;
            border-radius: 6px;
            padding-left: 39px;
            font-family: Ubuntu;
            font-size: 12px;
            text-transform: uppercase;
            color: #858BA5;
            list-style: none;

            > li:nth-of-type(1) { width: 13%; } // date
            > li:nth-of-type(2) { width: 12.5%; } // hour
            > li:nth-of-type(3) { width: 27.5%; } // name
            > li:nth-of-type(4) { width: 29%; } // location

            @media (width < 1300px) {
                > li:nth-of-type(4) { width: 26%; } // location
            }

            li > span {
                display: flex;
                align-items: center;
                gap: 2px;
                cursor: pointer;
                > img {
                    margin-left: 7px;
                }
                > svg {
                    width: 14px;
                }
            }

            > li:nth-of-type(2) { // hour
                > span {
                    cursor: default;
                    > img { display: none; } 
                }
            }
        }
    }

    .not-alerts {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
    
        > p {
            font-family: Ubuntu-Medium;
            font-size: 16px;
            font-weight: 500;
            color: #494F66;
        }

        > div {
            display: flex;
            align-items: center;
            gap: 10px;
            font-family: Ubuntu;
            font-size: 12px;
            color: #494F66;
        }
    }

    &__list {
        min-height: 615px;
        border-radius: 10px;
    }

    &__thead {
        margin: 3px 0;
        height: 30px;
        display: flex;
        align-items: center;
        background: #EBF2FE;
        border-radius: 6px;
        padding-left: 39px;
        font-family: Ubuntu;
        font-size: 12px;
        text-transform: uppercase;
        color: #858BA5;
        list-style: none;

        > li:nth-of-type(1) { width: 13%; } // date
        > li:nth-of-type(2) { width: 12.5%; } // hour
        > li:nth-of-type(3) { width: 27.5%; } // name
        > li:nth-of-type(4) { width: 29%; } // location

        @media (width < 1300px) {
            > li:nth-of-type(4) { width: 26%; } // location
        }

        li > span {
            display: flex;
            cursor: pointer;
            > img {
                margin-left: 7px;
            }
            > svg {
                margin-left: 7px;
                color: #3C7AF5;
                width: 13px;
                height: auto;
            }
        }

        > li:nth-of-type(2) { // hour
            > span {
                cursor: default;
                > img { display: none; } 
            }
        }
    }
}

.guard-detail-open {
    .header-container {
        display: none;
    }
}