.modal-filters-container {
    position: absolute;
    z-index: 100 !important;
}

.modal-filters-modal-container {
    padding: 0px;
    max-width: 468px;
    animation: openModal 0.3s ease;
    @media (orientation: portrait) {
        max-width: none !important;
        border-radius: 0 !important;
        background: transparent !important;
        animation: none;
    }
}

.modal-filters {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100dvh;
    overflow: hidden;
    animation: slideInFromRight 0.3s ease-out forwards;
    background-color: #F5F8FE;
    padding: 18px 15px;
    @media (orientation: portrait) {
        width: 100vw;
    }

    > img { // close icon
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
    }

    header {
        h4 {
            margin: 0;
            font-family: Ubuntu-Medium;
            font-size: 20px;
            font-weight: 500;
            color: #3C7AF5;
        }
    }

    &__content {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__select {
        &--input {
            height: 66px !important;
            display: flex;
            align-items: center;

            .MuiSelect-root {
                position: relative;
            }

            img {
                position: absolute;
                top: 6px;
                right: 19px;
                pointer-events: none;
            }
        }
        
        &--item {
            display: flex;
            align-items: center;
            gap: 14px;
            padding: 10px 17px !important;
    
            .checkbox-container {
                width: 20px;
                height: 20px;
                .unchecked { margin: 0; }
            }

            .MuiCheckbox-root {
                padding: 0;
                width: 20px;
                height: 20px;
            
                i {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            
                .icon{
                    font-size: 20px;
                    color: #62D862 !important;
                    margin: 0;
            
                    &::before{
                        margin:0
                    }
                }
            
                .MuiTouchRipple-root {
                    width: 18px;
                    height: 19px;
                    border: 1px solid #D9D9D9;
                    border-radius: 5px;
                }
            }    
    
            .MuiListItemText-root {
                margin: 0;
    
                > span {
                    margin: 0;
                    font-family: Ubuntu;
                    font-size: 14px;
                    font-weight: 400;
                    color: #494F66;
                }
            }
        }
    }

    footer {
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 77px;
        margin: 0;
        width: 100%;
        padding: 18px 15px 24px;
        background-color: #FFFFFF;
        display: flex;
        justify-content: space-between;
        
        button {
            width: 45%;
            height: 40px;
            font-size: 14px;
            font-family: Ubuntu-Medium;
            text-transform: uppercase;
            border: none;
            background: transparent;
            color: #494F66;
            text-align: start;
            cursor: pointer;

            &:disabled {
                opacity: 0.6;
                cursor: default;
            }

            > svg {
                margin-right: 14px;
            }
        }
        // confirm button
        button:last-of-type {
            text-align: center;
            color: #FFFFFF;
            border-radius: 40px;
            background-color: #494F66;
        }
    }
}