@import 'assets/styles/variables';
@import 'assets/styles/fonts';
@import 'assets/fontello/css/fontello.css';

.ArcActivityView__content {
  position: relative;
  height: calc(100dvh - 140px);
  overflow: hidden;
  max-width: none !important;
  padding: 40px 0 0 !important;
  @media (orientation: portrait) {
    padding: 25px 0 0 !important;
    height: calc(100dvh - (8vh + 56px));
  }
}

.ArcActivityView__header {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  margin: auto;
  padding: 0 5%;
  @media (orientation: portrait) {
    flex-direction: column-reverse;
  }

  > div:first-of-type {
    width: 70%;
    display: flex;
    gap: 10px;
    @media (orientation: portrait) {
      flex-direction: column;
      width: 100%;
    }
  }

  // DeviceReadPicker
  > div > div:nth-of-type(1):not(.main-input-toggle) {
    width: 40%;
    height: 60px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    @media (orientation: portrait) {
      width: 100%;
    }

    .MuiInputBase-root  {
      img { // icon dropdown select
        position: absolute;
        top: 20%;
        right: 15px;
      }
    }
  }
  // TagsReadsArc
  > div > div:nth-of-type(2) {
    width: 60%;
    @media (orientation: portrait) {
      width: 100%;
    }
  }
   // ToggleDeviceRead
   > div:nth-of-type(2) {
    margin-left: auto;
    padding-left: 0;
    width: 20%;
    display: flex;
    justify-content: flex-end;
    @media (orientation: portrait) {
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.ArcActivityView__tagsRead {
  margin: 0 5%;
  @media (orientation: portrait) {
    margin: 0 15px;
  }
}

.ArcActivityView__nav-tab {
  margin: 30px 0 20px;
  width: 100%;
  display: flex;
  gap: 20px;
  @media (min-width: 1090px) {
    pointer-events: none;
  }
  @media (orientation: portrait) {
    margin: 0 0 10px;
  }

  > button {
    width: calc(50% - 10px);
    height: 40px;
    font-family: Ubuntu-Medium;
    font-size: 16px;
    background: transparent;
    color: #B6B9C2;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-bottom: 10px;
    transition: 0.1s;
    cursor: pointer;
    box-sizing: border-box;
    @media (orientation: portrait) {
      gap: 10px;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }

    > p {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > span {
      box-sizing: border-box;
      width: 21px;
      height: 21px;
      color: #B6B9C2;
      border: 2px solid #B6B9C2;
      background-color: #EDEDF0;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .active, &-wide > button {
    color: $btn-text-color;
    border-bottom: 4px solid $btn-text-color;

    > span {
      color: $green;
      border: 2px solid $green;
      background: transparent;
    }
  }

  &-wide {
    margin: 30px 0 0;
    > button {
      border: none !important;
    }
  }
}

.ArcActivityView__list {
  width: 100%;
  background: transparent;
  overflow-y: auto;
  box-sizing: border-box;
  height: calc(100dvh - 327px);
  @media (orientation: portrait) {
    height: calc(100dvh - (8vh + 380px));
  }
  
  &-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    .ArcActivityView__destination-group {
      width: 100%;
    }
  }

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #a6a6a6;
    border-radius: 10px;
  }
}

.ArcActivityView__destination-group {
  background-color: #EBF2FE;
  padding: 0.5rem;
  border-radius: 0.5rem;
  min-height: 95%;
  @media (orientation: portrait) {
    background: none;
  }
}

.grid-item-view-arc {
  margin: 10px auto 0 !important;
  padding: 0 5%;
  overflow: hidden;
  height: calc(100dvh - 230px);
  @media (orientation: portrait) {
    padding: 0 15px;
    height: calc(100dvh - (8vh + 260px));
  }

  &__confirmation-view {
    overflow-y: auto;
    height: calc(100dvh - 247px);
    @media (orientation: portrait) {
      height: calc(100dvh - (8vh + 218px));
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #a6a6a6;
      border-radius: 10px;
    }
  }
}

.grid-column-arc {
  font-family: Ubuntu-Medium;
  font-size: 18px;
  color: #2f2e3c;
  border-radius: 10px;
  justify-content: center;
}

.ArcActivityView__confirmation-view {
  &:last-of-type {
    padding-bottom: 30px;
  }
  
  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > h4 {
      font-family: Ubuntu;
      font-size: 14px;
      color: $btn-text-color;
    }

    > span {
      width: 21px;
      height: 21px;
      color: $green;
      border: 2px solid $green;
      background: transparent;
      border-radius: 4px;
      display: grid;
      place-items: center;
    }
  }
}

.device-detail-container {
  .header-item {
    img { width: 24px; }
  }

  .main-app-generic-modal-wrapper {
    z-index: 100;
  }
}

// .count-view {
//   font-family: Ubuntu-Medium;
//   color: #2f2e3c;
//   font-size: 20px !important;
//   margin: 10px !important;
//   left: 40vw !important;
//   position: absolute;
// }

// .update {
//   margin-left: 50px !important;
// }

// .div-header-reads{
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
// }

// .count-tags {
//   color: #62d862;
//   font-size: 20px !important;
// }

// .icon-delete {
//   position: fixed;
// }

// .bottom-grid {
//   height: 50px;
//   bottom: 0;
// }