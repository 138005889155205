@import "../../../../../assets//styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.text-white {
  color: white;
  font-family: Ubuntu-Medium;
  width: 100%;
}

.hand {
  align-self: center !important;
  position: absolute;
  z-index: 100;
  display: flex;
  width: 39vw;
  padding-left: 11vw;
  margin-top: -8vh !important;
}

.detalle-prod {
  padding: 10px;
  font-size: 2vh;
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
  }

  video {
    max-width: 100%;
  }
}

.container-product-info {
  display: flex;
  height: 92vh;
  width: 100%;
}

.container-image {
  margin-top: 10px;
  width: 60vw;
  height: 16vh;
  overflow-x: auto;
  white-space: nowrap;
}

.column-product-info {
  box-sizing: border-box;
  width: 33vw !important;
  height: 90vh;
  max-height: 90vh;
  padding: 1vh;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(181, 177, 177, 0.5);
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.dark {
    border: none;
    background-color: #2b2a37 !important;
    box-shadow: none !important;
    border-radius: 0px;
    width: 90%;
    border-radius: 20px 0px 0px 20px;

    &.first {
      border-right: none;
      background-color: #2b2a37 !important;
    }

    &.detail {
      padding-left: 1vw;
      width: 34vw !important;
      border-radius: 0px 20px 20px 0px !important;
      border-left: none;
      margin-left: -1vw;
      background-color:  #2b2a37  !important;
    }
  
  }

  &.first {
    box-shadow: -5px 0px 10px rgba(181, 177, 177, 0.5);
    border-right: none;
    background-color: #fafafc !important;
  }

  &.detail {
    padding-left: 1vw;
    width: 34vw !important;
    box-shadow: 5px 0px 10px rgba(181, 177, 177, 0.5);
    border-radius: 0px 20px 20px 0px !important;
    border-left: none;
    margin-left: -1vw;
    background-color: #fafafc !important;
  }

  .description-tip {
    max-height: 8vh;
    height: 8vh;
    font-size: 1.5vh !important;
    margin-top: 1.5vh;
    overflow: hidden;
    position: relative;
    display: flex;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    &.animate .scroll-text {
      animation: scrollText 10s linear infinite;
    }
  }

  @keyframes scrollText {
    30% {
      transform: translateY(0);
    }

    70% {
      transform: translateY(-120%);
    }

    100% {
      transform: translateY(-120%);
    }
  }

  .images-related {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
    height: 10vh !important;


    div {
      margin: 10px;
      border: 1px solid lightgray;
      box-shadow: 0px 0px 10px rgba(181, 177, 177, 0.5) !important;
      max-width: 20%;
      height: 15vh;
      border-radius: 10px !important;

      h4 {
        margin-bottom: 5px;
      }

      img,
      video {
        max-width: 100% !important;
        object-fit: contain !important;
        background-color: white;
        height: 15vh;
        padding: 5px;
        border-radius: 10px;

        &:hover {
          z-index: 1;
          border: 2px solid;
          border-color: #3c7af5;
        }
      }
    }
  }

  .image-large {

    img,
    video {
      width: 100%;
      height: 60vh;
      max-height: 60vh;
      object-fit: contain;
    }
  }


  .image-carousel {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    overflow: auto;
    position: relative;
    height: 13vh;

    &.dark {

      img,
      video {
        border: none;
        box-shadow: none;
        border-radius: 10px;

        &:hover {
          z-index: 1;
          border: 2px solid;
          border-color: #3c7af5;
        }
      }

    }

    img,
    video {
      max-height: 10vh;
      object-fit: cover;
      transition: transform 0.3s ease-in-out;
      transform-origin: center center;
      margin: 5px;
      border: 1px solid lightgray;
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(181, 177, 177, 0.5);

      &:hover {
        z-index: 1;
        border: 2px solid;
        border-color: #3c7af5;
      }
    }
  }

  .image-carousel::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari y Edge */
  }

  .price {
    font-size: 2vh;
    margin-top: 1vh;
    margin-bottom: 1vh;
    color: #50f757;
  }
}

.recomendation {
  width: 33vw !important;
}

.image-thumbnails {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 10vh;
  overflow: visible;
  position: relative;
  display: inline-block;

  &.dark {
    img {
      border: none;
      box-shadow: none;
      border-radius: 10px;
    }
  }

  img {
    height: 8vh;
    margin: 5px;
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(181, 177, 177, 0.5);

    &:hover {
      z-index: 1;
      border: 2px solid;
      border-color: #3c7af5;
    }
  }
}

.btnShare{
    margin: 2vh;
    width: 200px;
    button {
      height: 50px !important;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }
}


.btnCancel{
    margin: 20px 10px 0px 20px;
    width: 250px;
}

.btnSend {
  margin: 20px 10px 0px 20px;
  width: 250px;
  border-radius: 20px;

  button {
    background-color: #3c7af5 !important;
    color: white !important;
  }
}

.btnContainer {
  display: flex;
  width: 70%;
  justify-content: space-around;
}

@media (orientation: portrait) {

  .dark-modal {
    background-color: #201f28 !important;
    color: white;
  }

  .hand {
    align-self: center !important;
    position: absolute;
    z-index: 100;
    display: flex;
    width: 39vw;
    padding-left: 18vw;
    margin-top: -40px
  }

  .column-product-info {
    height: 25vh;
    width: 50vw !important;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(181, 177, 177, 0.5);
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &.first {
      height: 64vh;
      width: 50vw;
    }

    &.dark {
      border: none;
      background-color: #2b2a37 !important;
      box-shadow: none !important;
      border-radius: 0px;
      width: 90%;
      border-radius: 20px 0px 0px 20px;


      &.first {
        height: 64vh;
        width: 45vw !important;
        background-color: #2b2a37;
      }

      &.detail {
        height: 64vh;
        width: 50vw !important;
        border-radius: 0px 20px 20px 0px;
        margin-left: -10%;
      }
    }

    &.first {
      width: 45vw !important;
    }

    &.detail {
      height: 64vh;
      width: 54vw !important;
      border-radius: 0px 20px 20px 0px;
      margin-left: -10%;
    }

    .description-tip {
      max-height: 8vh;
      height: 8vh;
      font-size: 1.5vh !important;
      margin-top: 1.5vh;
      overflow: hidden;
      position: relative;
      display: flex;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }

      &.animate .scroll-text {
        animation: scrollText 10s linear infinite;
      }
    }

    @keyframes scrollText {
      30% {
        transform: translateY(0);
      }

      70% {
        transform: translateY(-120%);
      }

      100% {
        transform: translateY(-120%);
      }
    }

    .images-related {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 15px;
      height: 10vh !important;
  
  
      div {
        margin: 10px;
        border: 0px solid lightgray;
        box-shadow: 0px 0px 10px rgba(181, 177, 177, 0.5) !important;
        height: 10vh;
        border-radius: 10px !important;
  
        h4 {
          margin-bottom: 5px;
        }
  
        img,
        video {
          max-width: 100% !important;
          object-fit: contain !important;
          background-color: white;
          height: 10vh;
          padding: 5px;
          border-radius: 10px;
  
          &:hover {
            z-index: 1;
            border: 2px solid;
            border-color: #3c7af5;
          }
        }
      }
    }

    .image-large {

      img,
      video {
        width: 100%;
        max-height: 35vh;
        object-fit: contain;
        border-radius: 34px;
        background-color: white;
      }
    }
  }

  .recomendation {
    width: 98vw !important;

    &.dark {
      height: 20vh;
      width: 95vw !important;
      border-radius: 34px;
    }
  }


  .cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #22232e;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

    &.dark {
      top: 85px;
    }

    img {
      max-width: 100%;
    }

    video {
      max-width: 100%;
      height: 100vh;
      width: 100vw;
      background: black;
    }
  }

  .container-image {
    margin-top: 10px;
    width: 88vw;
    height: 12vh;
    overflow-x: auto;
    white-space: nowrap;

    img,
    video {
      padding: 0px;
      height: 10vh;

      &:hover {
        z-index: 1;
        border: 2px solid;
        border-color: #3c7af5;
      }
    }
  }

  .container-image::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari y Edge */
  }



}