.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
  left: 0;
}

.react-datepicker-popper {
  left: 24px !important;
}

.react-datepicker__triangle {
  color: #e4e5e5 !important;
  @media (width > 1300px) {
    left: 204px !important;
  }
}

.react-datepicker__input-container > div:first-of-type {
  position: relative;
  height: fit-content;
}

.react-datepicker__input-container > div:first-of-type > img {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
}

.react-datepicker {
  border: none;
  fill: var(--Blanco, #FFF);
  filter: drop-shadow(0px 0px 0.935px rgba(0, 0, 0, 0.04))
          drop-shadow(0px 1.87px 5.61px rgba(0, 0, 0, 0.04))
          drop-shadow(0px 9.351px 18.701px rgba(0, 0, 0, 0.04));
}

.react-datepicker__current-month {
  height: 58px;
  background-color: #F7F8FA;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__navigation {
  top: 18px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  right: 12px;
  @media (orientation: portrait) {
    top: 16px !important;
  }
}

/* Change background color when selecting. */
.react-datepicker__day--keyboard-selected {
  border-radius: 50% !important;
  background-color: #B1CAFB !important;
  z-index: -1 !important;
}

/* Change background color when hovering. */
.react-datepicker__day:hover:not(
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end,
  .react-datepicker__day--keyboard-selected
) {
  border-radius: 50% !important;
  background-color: #f2f4f7 !important;
  color: #101828;
}

/* Change background color when selecting. */
.react-datepicker__day--in-range {
  background-color: #f2f4f7 !important;
  color: #040930 !important;
  border-radius: 50% !important;
  z-index: 5 !important;
}

/* Change background color when selecting. */
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: #B1CAFB !important;
  position: relative !important;
  z-index: 1 !important;
}

/* Change background color when selecting. */
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--keyboard-selected) {
  background-color: #f2f4f7 !important;
  color: #040930;
  border-radius: 50% !important;
}

.react-datepicker__header {
  background-color: white;
  border: none;
  padding: 0 !important;
}

.react-datepicker__day--outside-month {
  color: #888; 
}

.react-datepicker-wrapper {
  width: 25%;
  max-width: 280px;
  height: 50px;
  @media (orientation: portrait) {
    width: 100%;
    max-width: none;
    height: 66px !important;
    background-color: #FFFFFF;
  }
}

.react-datepicker-wrapper .MuiInputBase-root {
  height: 50px;
  cursor: pointer;
}

.custom-date-input,
.custom-date-input-complete {
  width: 100%;
  background-color: #FFFFFF;
}

.custom-date-input > img,
.custom-date-input-complete > img {
  cursor: pointer;
}

.custom-date-input input {
  font-family: Ubuntu-Light;
  color: #A2A9C8 !important;
  cursor: pointer;
  padding: 16.5px 14px;
}

.custom-date-input-complete input {
  font-family: Ubuntu-Medium;
  font-size: 16px;
  font-weight: 500;
  color: #494F66 !important;
  cursor: pointer;
  padding: 16.5px 14px;
  max-width: 70%;
  text-overflow: ellipsis;
}

.custom-date-input > .MuiInputBase-root,
.custom-date-input-complete > .MuiInputBase-root {
  height: 49px !important;
  @media (orientation: portrait) {
    height: 66px !important;
  }
}

.custom-date-input .Mui-focused fieldset,
.custom-date-input-complete .Mui-focused fieldset
 {
  border: 1px solid #3C7AF5 !important;
}

.custom-date-input:hover fieldset,
.custom-date-input-complete:hover fieldset,
.custom-date-input-calendar-open fieldset {
  border: 1px solid rgba(var(--mui-palette-common-onBackgroundChannel) / 0.23) !important;
}

.custom-date-input:hover .Mui-focused fieldset,
.custom-date-input-complete:hover .Mui-focused fieldset,
.custom-date-input-calendar-open:hover fieldset {
  border: 1px solid #3C7AF5 !important;
}

.react-datepicker__portal {
  background-color: rgba(73, 79, 102, 0.5);
}

.react-datepicker__portal .react-datepicker {
  border-radius: 10px;
  overflow: hidden;
}

.react-datepicker__portal .react-datepicker__current-month {
  font-family: Ubuntu-Medium;
  font-size: 18px;
  font-weight: 500;
  color: #343434;
}

.react-datepicker__children-container {
  width: auto;
}

.react-datepicker__action-buttons {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.react-datepicker__action-buttons > button {
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-family: Ubuntu-Medium;
  font-size: 14px;
  font-weight: 500;
  color: #3C7AF5;
}

.react-date-picker__clear-button {
  position: absolute;
  top: 55%;
  right: 50px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
}