.div-colours {
    display: flex;
    margin-top: 1vh;
}

.div-sizes {
    margin-top: 1vh;
    margin-bottom: 1vh;
    display: flex;
}

.colours{
    display: block;
}

.dot-selected{
    font-size: 50px;
    margin-top: 55px;
}