.modal-process-alert-container {
    position: absolute;
    z-index: 100 !important;
}

.modal-process-alert-modal-container {
    padding: 0px;
    max-width: 468px;
    animation: openModal 0.3s ease;
    @media (orientation: portrait) {
        max-width: none !important;
        border-radius: 0 !important;
        background: transparent !important;
        animation: none;
    }
}

.modal-process-alert {
    width: 100%;
    @media (orientation: portrait) {
        box-sizing: border-box;
        height: 100dvh;
        padding: 20px 16px 0;
        animation: slideInFromRight 0.3s ease-out forwards;
        background-color: #F5F8FE;
    }

    .modal-process-alert__content {
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background: #a6a6a6;
            border-radius: 10px;
        }
    }

    h4, p, span {
        margin: 0;
        font-family: Ubuntu;
    }

    > header {
        display: flex;
        align-items: center;
        gap: 17px;
        img { 
            cursor: pointer;
            @media (orientation: portrait) {
                filter: grayscale(100%) sepia(100%) hue-rotate(170deg) saturate(400%) contrast(100%);
            }
        }
        h4 {
            font-size: 20px;
            font-weight: 600;
            color: #494F66;
            @media (orientation: portrait) {
                color: #3C7AF5;
            }
        }
    }

    &__content {
        max-height: 65vh;
        overflow-y: scroll;
        padding-right: 10px;
        @media (orientation: portrait) {
            max-height: 80vh;
            padding-right: 0;
        }

        > p {
            margin-top: 25px;
            font-size: 16px;
            font-weight: 400;
            color: #6D7285;
        }
    }

    &__options {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        &--button {
            width: 100%;
            height: 70px;
            padding: 5px;
            background-color: #FFFFFF;
            border: 1px solid #DCDDE3;
            border-radius: 10px;
            display: flex;
            align-items: center;
            gap: 16px;
            cursor: pointer;
    
            picture {
                width: 60px;
                height: 60px;
                background-color: #F5F8FE;
                display: grid;
                place-items: center;
                border-radius: 4px;
            }
    
            h3 {
                font-family: Ubuntu-Medium;
                font-size: 20px;
                font-weight: 500;
                color: #5F6388;
            }
        }

        .option-THEFT-active {
            border: 2px solid #F55D68;
            background-color: #FEEFF0;
        }
        .option-ATTEMPTED_THEFT-active {
            border: 2px solid #FFAF3A;
            background-color: #FFF7EB;
        }
        .option-FALSE_ALARM-active {
            border: 2px solid #62D862;
            background-color: #F7FDF7;
        }
        .option-NOT_PROCESS-active {
            border: 2px solid #A65BE8;
            background-color: #F7EFFD;
        }
    }

    &__comment {
        box-sizing: border-box;
        margin-top: 25px;
        height: 132px;
        border: 1px solid #DBDCE0;
        padding: 14px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        @media (orientation: portrait) {
            background-color: #FFFFFF;
        }

        > span {
            font-size: 12px;
            color: #A9B1D1;
        }
        > textarea {
            height: 100%;
            max-width: 387px;
            max-height: 80px;
            resize: none;
            border: none;
            outline: none;
            font-family: Ubuntu;
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            &::placeholder {
                font-family: Ubuntu-Medium;
                color: #858BA5;
            }
            &::-webkit-scrollbar {
                width: 3px;
              }
            &::-webkit-scrollbar-thumb {
                background: #A9B1D1;
                border-radius: 30px;
            }
        }
    }

    .comment-focused {
        border-color: #3C7AF5;
        > span {
            color: #3C7AF5;
        }
    }

    footer {
        margin-top: 40px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media (orientation: portrait) {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 77px;
            margin: 0;
            box-sizing: border-box;
            width: 100%;
            padding: 18px 15px 24px;
            background-color: #FFFFFF;
        }
        
        button {
            width: 136px;
            height: 40px;
            font-size: 14px;
            font-family: Ubuntu-Medium;
            text-transform: uppercase;
            border: none;
            background: transparent;
            color: #3C7AF5;
            text-align: start;
            cursor: pointer;

            &:disabled {
                opacity: 0.6;
                cursor: default;
            }
        }
        // confirm button
        button:last-of-type {
            text-align: center;
            color: #FFFFFF;
            border-radius: 40px;
            background-color: #3C7AF5;
        }
    }
}