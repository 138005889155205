@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

@import '../../../../assets/fontello/css/fontello.css';


.main-button {
  border-radius: $btn-border-radius;
  border-width: 0;
  height: $btn-height;
  width: 100%;
  color: white;
  text-transform: uppercase;
  font-size: $btn-font-size;
  font-family: $btn-font;

  &:focus {
    outline: none;
    border: none
  }

  &.enabled {
    background-color: $blue;
    cursor: pointer;
  }

  &.dark {
    background-color: #ffffff;
    color: #3c7af5;
    border: 3px solid #3c7af5;
    height: 50px;
    cursor: pointer;
  }

  &.dark-dark {
    background-color: #201f28 ;
    color: #ffffff;
    border: 3px solid #ffffff;
    height: 50px;
    cursor: pointer;
  }

  &.disabled {
    background-color: $grey;
  }

  &.temporary {
    background-color: $green;
  }

  &.waiting {
    background-color: $lightBlue;
  }

  .main-button-icon {
    font-size: 14px;
  }

  @keyframes ldio-zwyik6gooc {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  .ldio-zwyik6gooc div {
    position: absolute;
    width: 60px;
    height: 60px;
    border: 10px solid #fefefe;
    border-top-color: transparent;
    border-radius: 50%;
  }

  .ldio-zwyik6gooc div {
    animation: ldio-zwyik6gooc 1s linear infinite;
    top: 50px;
    left: 50px
  }

  .loadingio-spinner-rolling-oq9o3ny4ta {
    width: 24px;
    height: 24px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }

  .ldio-zwyik6gooc {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.24);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
  }

  .ldio-zwyik6gooc div {
    box-sizing: content-box;
  }

  /* generated by https://loading.io/ */


  &:focus {
    border: none;
    outline-width: 0;
  }
}



@media (orientation: portrait) {

  .main-button {
    border-radius: $btn-border-radius;
    border-width: 0;
    height: $btn-height;
    font-size: $btn-font-size;
    font-family: $btn-font;
  }
}