
@import "assets/styles/fonts";
@import "assets/styles/variables";

.toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #676f8f;
    font-family: Ubuntu-Light;
    font-size: 14px;
    padding-left: 31px;

    @media (orientation: portrait) {
        order: 1;
        margin-bottom: 25px;
    }

    p {
        margin: 0;
        margin-right: 10px;
    }

    span {
        font-family: Ubuntu-Medium;
    }
}