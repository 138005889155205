@import "assets/styles/variables";

.tags_reads_arc {
  padding: 5px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0.49px 0.49px 1.96px 0px #5F638821;
  height: 60px;
  box-sizing: border-box;

  > article {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 12px;
    border-radius: 10px;
    background: #F5F8FE;
    
    > div {
      display: flex;
      align-items: center;
      gap: 8px;

      picture {
        width: 27px;
        height: 27px;
        background-color: #FFFFFF;
        display: grid;
        place-content: center;
        border-radius: 50%;
        
        img {
          width: fit-content;
          height: fit-content;
        }
      }

      h4 {
        margin: 0;
        font-family: Ubuntu;
        font-size: 14px;
        color: $btn-text-color;
      }
    }
      
    > span {
      margin: 0;
      color: $green;
      font-family: Ubuntu-Medium;
      font-size: 38px;  
    }
  }
}