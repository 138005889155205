.modal_device_activation-container {
    position: absolute;
    z-index: 100 !important;
    max-width: 468px;
}

.modal_device_activation-modal-container {
    padding: 0px;
    max-width: 468px;
    animation: openModal 0.3s ease;
    overflow: hidden;
    margin: 0;
    @media (orientation: portrait) {
        max-width: none !important;
        border-radius: 0 !important;
        background: transparent !important;
        animation: none;
    }
}

.modal_device_activation {
    width: 100%;
    max-width: 468px;
    overflow: hidden;
    position: relative;
    @media (orientation: portrait) {
        width: 100dvw;
        height: 100dvh;
        max-width: none;
        animation: slideInFromRight 0.3s ease-out forwards;
    }

    > img { // close icon
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
        @media (orientation: portrait) {
            left: 16px;
        }
    }

    header {
        display: flex;
        gap: 28px;
        box-shadow: 0px 1.89px 3.21px 0px #2948981C;
        background-color: #EBF2FE;
        padding: 55px 50px 45px 50px;
        @media (orientation: portrait) {
            max-height: 131px;
            padding: 50px 29px 26px 44px;
        }

        > img {
            width: 50px;
            height: 55px;
        }
        
        h4, p, span { margin: 0 }

        > div { // box content text
            display: flex;
            flex-direction: column;
            gap: 8.5px;
        }

        h4 {
            font-family: Ubuntu-Medium;
            font-size: 18px;
            font-weight: 500;
            color: #494F66;
        }
        p {
            font-family: Ubuntu;
            font-size: 14px;
            color: #6D7285;
            display: flex;
            align-items: center;
            gap: 10px;
            
            > span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background-color: #3C7AF5;
                font-family: Ubuntu-Medium;
                font-size: 10px;
                font-weight: 700;
                color: #FFFFFF;
            }
        }
    }

    ul {
        list-style: none;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 7px;
        margin: 26px 0;
        padding: 0;
        max-height: 30vh;
        overflow-y: auto;
        @media (orientation: portrait) {
            height: calc(100dvh - 240px);
            max-height: none;
            margin: 0;
            padding: 20px 0;
            background: #F5F8FE;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background: #a6a6a6;
            border-radius: 10px;
        }

        li {
            box-sizing: border-box;
            width: 90%;
            padding: 20px;
            border: 1px solid #DCDDE3;
            border-radius: 10px;
            font-family: Ubuntu-Medium;
            font-size: 18px;
            font-weight: 500;
            color: #5F6388;
            display: flex;
            align-items: center;
            gap: 9px;
            cursor: pointer;
            @media (orientation: portrait) {
                background-color: #FFFFFF;
            }

            .checkbox-container {
                width: 27px;
                height: 24px;
                overflow: hidden;
                margin-bottom: 2px;

                button {
                    box-sizing: border-box;
                    width: 27px;
                    height: 24px;
                    i { height: 20px; }
                    .unchecked { margin: 0; }
                }
            }
        }
    }


    footer {
        box-sizing: border-box;
        width: 90%;
        margin: 40px auto 32px;
        display: flex;
        justify-content: space-between;
        @media (orientation: portrait) {
            position: absolute;
            bottom: 0;
            height: 77px;
            margin: 0;
            width: 100%;
            padding: 18px 15px 24px;
            background-color: #FFFFFF;
        }
        
        button {
            width: 136px;
            height: 40px;
            font-size: 14px;
            font-family: Ubuntu-Medium;
            text-transform: uppercase;
            border: none;
            background: transparent;
            color: #3C7AF5;
            text-align: start;
            cursor: pointer;

            &:disabled {
                opacity: 0.6;
                cursor: default;
            }
        }
        // confirm button
        button:last-of-type {
            text-align: center;
            color: #FFFFFF;
            border-radius: 40px;
            background-color: #3C7AF5;
        }
    }
}