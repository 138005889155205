@import "~assets/styles/variables";
@import "~assets/styles/fonts";
@import "~assets/fontello/css/fontello.css";

$traceability-breakpoint: 900px;

.desktop-main-layout-container {
  height: 100dvh;
}

@media (max-height: $traceability-breakpoint) and (orientation: landscape) {
  .sidebar-container {
    .home {
      margin: 0;
    }
  }
}
.tag-traceability-grid-container {
  margin-top: 20px !important;
  margin: 0;
  width: 100%;
  padding: 18px 0;
  border-radius: 10px;
  background: white;
  margin-bottom: 15px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  .tags-reader-container {
    margin: 18px 0;
    padding-left: 24px;

    .tags-reader-content {
      .tags-reader-field {
        padding: 10px 0px;

        .load-movement-items-input {
          display: block;

          svg {
            display: none;
          }
        }
      }
    }
  }

  .tag-controls-container {
    background-color: red;
    margin-bottom: 36px;
  }

  @media (orientation: portrait) {

    padding: 18px 0;

    .tags-reader-container {
      padding: 0 10px;
    }
  }

}

.tag-traceability-list{
  padding-top: 3px !important;
  padding-bottom: 8px !important;
}

.tag-traceability-container {
  overflow: auto;
  height: 90vh !important;
  min-height: auto;
  max-height: none;
  max-width: none !important;
  padding-left: 5vw !important;
  padding-right: 5vw !important;

  .tag-traceability-content {
    padding-bottom: 24px !important;

    .MuiList-root {
      .MuiListItemText-root{
        &:first-child {
          .table-icon {
            &::before {
              display: none;
            }
          }
        }

      }
    }

    @media (max-height: $mobile-height) and (max-width: $traceability-breakpoint) {
      padding-bottom: 57px !important;
    }
  }
}

.items-buttons-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.items-button {
  cursor: pointer;
  font-family: $btn-font;
  font-size: $btn-font-size;
  border-radius: $btn-border-radius;
  width: $btn-width-short;
  height: $btn-height;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  background-color: $btn-color-bg-primary;
}

.items-clean {
  background-color: $btn-color-bg-dark;
  margin-right: 68px;
}

// Acá estilo la primer y última fila
.MuiTableRow-root {
  &:first-child {
    .MuiTableCell-body {
      &:first-child {
        .table-icon {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  &:last-child {
    .MuiTableCell-body {
      &:first-child {
        .table-icon {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.table-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: white;
  border: 1px solid #A9B1D1;
  border-radius: 50rem;
  position: relative;

  margin: 0 !important;
  padding: 0 !important;

  .circle {
    margin: 0 !important;
    padding: 0 !important;
    background-color: #A9B1D1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50rem;

  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    background-color: #A9B1D1;
  }


  &::before {
    left: 11px;
    top: -37px;
    width: 2px;
    height: $btn-height;
    z-index: 0;

    @media (max-width: $traceability-breakpoint) {
      top: -28px;
    height: 28px;
    }
  }

  &::after {
    left: 11px;
    bottom: -42px;
    width: 2px;
    height: 41px;
    z-index: 0;

    @media (max-width: $traceability-breakpoint) {
      bottom: -112px;
      height: 112px;
    }
  }

  @media (max-width: $traceability-breakpoint) {
    margin-top: -36px  !important;
  }


}

.footer {
  position: fixed;
  right:  0;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: white;
  text-align: center;
  display: none;

  @media (max-width: $traceability-breakpoint) {
    display: inline-block;
  }
}
