@import 'assets/styles/variables';

.ActionButtonsArc {
  position: fixed !important;
  bottom: 0;
  left: 77px;
  width: calc(100% - 77px);
  height: 77px;
  background-color: #FFFFFF;
  @media (orientation: portrait) {
    position: absolute !important;
  }

  > div {
    height: 100%;
    margin: auto;
    padding: 0 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 940px) {
      padding: 0 20px;
    }

    button {
      height: 36px;
      width: $btn-width;
      background-color: $btn-text-color;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-family: $btn-font;
      font-size: $btn-font-size;
      text-transform: uppercase;
      cursor: pointer;

      &:first-of-type {
        background: transparent;
        color: $btn-black;
        width: 120px;
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }

  &__success-button {
    width: 200px !important;
    background-color: $btn-text-color !important;
    color: #fff !important;
    margin: auto;
  }
}